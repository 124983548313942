@font-face {
  font-family: Fira Code;
  src: local(Fira Code), url("FiraCode-VF.ec09f35c.woff2") format("woff2");
}

* {
  color: var(--col-text);
  font-family: Fira Code, monospace;
}

html, body {
  height: 100%;
}

body {
  background-color: var(--col-bg);
  margin: 0;
}

#editor {
  width: 100%;
  height: 100%;
}

.background {
  z-index: -100;
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (prefers-color-scheme: light) {
  :root {
    --col-bg: #d8d5d4;
    --col-bg-shadow: #ffffff59;
    --col-bg-shadow-soft: #ffffff1f;
    --col-text: #252221;
    --col-text-soft: #4b4542;
    --col-text-1: #e59e00;
    --col-text-2: #e54c2e;
    --col-text-3: #0f91ea;
    --col-text-4: #9e38ad;
    --col-text-invert: #fff;
    --col-selection: #c5c0be;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --col-bg: #35312f;
    --col-bg-shadow: #0b0a094d;
    --col-bg-shadow-soft: #0b0a091f;
    --col-text: #eceae9;
    --col-text-soft: #b2aba8;
    --col-text-1: #f9c112;
    --col-text-2: #f76559;
    --col-text-3: #2fa8f2;
    --col-text-4: #c44ed8;
    --col-text-invert: #0b0a09;
    --col-selection: #605956;
  }
}

.cm-console {
  max-height: 30vh;
  margin: 12px 18px 0;
  line-height: 1.4;
}

.cm-console:last-child {
  margin-bottom: 18px;
}

.cm-console-message {
  margin: 0;
}

.cm-console-message-source, .cm-console-message-input, .cm-console-message-output {
  padding: 4px 6px;
}

.cm-console-message-source {
  padding-left: 12px;
}

.cm-console {
  overflow-y: auto;
}

.cm-console-message {
  flex-direction: row-reverse;
  display: flex;
}

.cm-console-message:not(:first-child) {
  clip-path: inset(1px 0 0);
}

.cm-console-message:not(:last-child) {
  clip-path: inset(0 0 1px);
}

.cm-console-message:not(:first-child):not(:last-child) {
  clip-path: inset(1px 0);
}

.cm-console-message-content {
  overflow-wrap: anywhere;
  flex: 1;
}

.cm-console-message-info .cm-console-message-source, .cm-console-message-info .cm-console-message-input {
  border-color: var(--col-bg-shadow);
  background-color: var(--col-bg-shadow-soft);
}

.cm-console-message-info .cm-console-message-output {
  background-color: var(--col-bg-shadow);
}

.cm-console-message-info .cm-console-message-source, .cm-console-message-info .cm-console-message-input {
  color: var(--col-text-soft);
}

.cm-console-message-error .cm-console-message-input, .cm-console-message-error .cm-console-message-output {
  background-image: repeating-linear-gradient(135deg, #6c000050 0 10px, #a6020250 10px 20px);
}

.cm-console-message-error .cm-console-message-source {
  color: coral;
}

.cm-console-message-input {
  padding-left: 24px;
  position: relative;
}

.cm-console-message-input:before {
  box-sizing: border-box;
  background: var(--col-bg-shadow-soft);
  width: 24px;
  height: 100%;
  padding: 4px 6px;
  position: absolute;
  top: 0;
  left: -24px;
}

.invisible {
  clip: rect(0 0 0 0);
  width: 0;
  height: 0;
  margin: -1px;
  position: absolute;
  left: 0;
  overflow: hidden;
}

/*# sourceMappingURL=index.b9d0da2f.css.map */
